
import { defineComponent, PropType } from 'vue';
import { useDateTime } from '@/core/hooks/use-date-time-hook';
import { useRouterCustom } from '@/core/hooks/use-router-custom';
import { IHopDongResponseBase } from '@/core/interfaces/ApiResponses';
import { usePagination } from '@/core/hooks/use-pagination';
import { useButtonCustom } from '@/core/hooks/use-button-custom';
import ButtonCustomVue from '../../../../../components/shared/buttons/ButtonCustom.vue';
import useHopDong from '@/core/hooks/use-hop-dong';

export default defineComponent({
  name: 'hop-dong-table',

  components: { ButtonCustomVue },

  props: {
		curPage: {
			type: Number,
			default: 0,
		},
    items: {
      type: Array as PropType<Array<IHopDongResponseBase>>
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
		perPage: {
			type: Number,
			default: 0,
		},
  },

  setup() {
    const { moment } = useDateTime();

    const { push } = useRouterCustom();

		const { indexCal } = usePagination();

    const { ButtonTypeColors, ButtonsType } = useButtonCustom();

    const { loaiHopDong } = useHopDong();

    return {
      ButtonTypeColors, ButtonsType,
      indexCal,
      loaiHopDong,
      moment,
      push,
    }
  }
})
