import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_button_custom_vue = _resolveComponent("button-custom-vue")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    "empty-text": "Chưa có dữ liệu hợp đồng",
    "max-height": "800",
    style: {"width":"100%"},
    size: "small",
    "cell-style": {padding: '0', height: '15px'},
    border: true,
    data: _ctx.items
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "STT",
        width: "60"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_ctx.indexCal(scope.$index, _ctx.curPage, _ctx.perPage)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "ID",
        width: "60"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.id), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Số hợp đồng" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.so_hop_dong), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Loại hợp đồng",
        width: "120"
      }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_ctx.loaiHopDong(scope.row)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Đối tác" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ten_doi_tac), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Mã số thuế" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(scope.row.ma_so_thue), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Ngày ký" }, {
        default: _withCtx((scope) => [
          _createTextVNode(_toDisplayString(_ctx.moment(scope.row.ngay_ky).format('DD/MM/YYYY')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        width: "100",
        fixed: "right"
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_button_custom_vue, {
              "button-type": _ctx.ButtonsType.INFO,
              "color-type": _ctx.ButtonTypeColors.INFO,
              onClick: ($event: any) => (_ctx.push(`/quan-ly/hop-dong/chi-tiet/${scope.row.id}`))
            }, null, 8, ["button-type", "color-type", "onClick"]),
            _createVNode(_component_button_custom_vue, {
              "button-type": _ctx.ButtonsType.EDIT,
              "color-type": _ctx.ButtonTypeColors.SUCCESS,
              onClick: ($event: any) => (_ctx.push(`/quan-ly/hop-dong/cap-nhat/${scope.row.id}`))
            }, null, 8, ["button-type", "color-type", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"])), [
    [_directive_loading, _ctx.isLoading]
  ])
}