
import { defineComponent, ref } from "vue";
import { debounce } from 'lodash';
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { HopDongService } from "@/core/services/HopDong.service";
import { useRouterCustom } from "../../../../core/hooks/use-router-custom";
import { swalNotification, getErrorMsg } from "../../../../core/helpers/utils";
import { IHopDongResponseBase } from "../../../../core/interfaces/ApiResponses";
import { useLoading } from "../../../../core/hooks/use-loading";
import { usePagination } from "../../../../core/hooks/use-pagination";
import { useButtonCustom } from '../../../../core/hooks/use-button-custom';

import PageRowLayout from "../../../../layout/_shared/PageRowLayout.vue";
import ButtonCustom from "../../../../components/shared/buttons/ButtonCustom.vue";
import HopDongTable from "./hop-dong-table/HopDongTable.vue";
import PerPageSelection from '@/components/shared/per-page-selection/PerPageSelection.vue';
import Pagination from "../../../../components/shared/pagination/Pagination.vue";

export default defineComponent({
  name: 'danh-sach-hop-dong',

  components: {
    PageRowLayout,
    ButtonCustom,
    HopDongTable,
    PerPageSelection,
    Pagination,
  },

  setup() {
    const { ButtonsType } = useButtonCustom();
    const { push } = useRouterCustom();
    const { startLoading, endLoading, isLoading } = useLoading();
    const { currentPage, totalItems, perPage, perPageArr, searchTerm } = usePagination();

    const hopDongItems = ref<IHopDongResponseBase[]>([]);

    return {
      ButtonsType,
      hopDongItems,
      push,
      startLoading, endLoading, isLoading,
      currentPage, totalItems, perPage, perPageArr, searchTerm,
    };
  },

  async mounted() {
    setCurrentPageTitle('Hợp đồng');

    await this.fetchData();
  },

  watch: {
		async currentPage() {
			await this.fetchData();
		},

		async perPage() {
			await this.fetchData();
		}
	},

  methods: {
    async fetchData() {
      try {
        this.startLoading();

        const {
          data: {
            data: { data, total },
          },
        } = await HopDongService.fetchAllContracts(
          this.currentPage,
          this.perPage,
          this.searchTerm,
        );

        this.hopDongItems = data;

        this.totalItems = total;

        this.endLoading();
      } catch (error) {
        this.endLoading();
        await swalNotification(
          getErrorMsg(error, 'Xin lỗi, có vẻ có lỗi xảy ra khi tải danh sách hợp đồng'),
          'error',
        )
        await this.push('/trang-chu');
      }
    },

    searchForHopDong: debounce(function() {
      this.fetchData();
    }, 500),
  }
});
